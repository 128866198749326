// Here you can add other styles

@import "~@coreui/coreui/scss/functions";
@import "~@coreui/coreui/scss/variables";
@import "~@coreui/coreui/scss/mixins/breakpoints";

.react-select__input input {
  width: 100% !important;
  height: 100% !important;
  opacity: 1 !important;
}

@include media-breakpoint-up(md) {
  .body,
  .wrapper .header {
    margin-left: 60px;
  }

  .sidebar.hide:not(.sidebar-end) {
    margin-left: 0;
  }
}
